<template>
<v-app :style="{ background: '#efefef' }">
    <div class="container">
        <h4>Motivo de consulta</h4>
        <input v-model="motivo_consulta" type="text" @input="update($event, 'motivo_consulta')">
        <h4>Enf actual y evolución</h4>
        <input v-model="enf_actual" type="text" @input="update($event, 'enf_actual')">
        <h4>Revisión por sistemas</h4>
        <input v-model="revision_sistemas" type="text" @input="update($event, 'revision_sistemas')">
        <h4>Tratamiento previo:</h4>
        <input v-model="tratamiento_previo" type="text" @input="update($event, 'tratamiento_previo')">
        <h4>Antecedentes:</h4>
        <p class="text-grey">
            Escriba en este campo información para agregar a los antecedentes del paciente.
        </p>
    </div>
    <div class="text-container">
        <textarea v-model="antecedentes" :class="{'text-grey': !editTextArea, 'text-dark': editTextArea }" :disabled="!editTextArea" @input="update($event, 'antecedentes')">
        </textarea>
        <v-btn v-if="!editTextArea" @click="updateTextArea" class="button-relative" small color="green" fab>
            <v-icon color="white">mdi-pencil</v-icon>
        </v-btn>
    </div>
    <div class="last-container">
        <v-text-field label="Antecedentes..." v-model="antecedentes2" @input="update($event, 'antecedentes2')" class="mb-5"></v-text-field>
        <v-btn block elevation="2" color="#4451b4" style="color: white;" class="mb-3 mt-10" @click="$router.push({name: 'DatosClinicos'})">
            SIGUIENTE
        </v-btn>
        <v-btn block elevation="2" color="#d6d8d7" @click="$router.push({name: 'DatosPaciente'})">
            ATRÁS
        </v-btn>
    </div>
</v-app>
</template>

<script>
import {
    mapState
} from "vuex";
import sweetAlert from "@/modules/sweetAlert";
export default {
    name: "DatosPaciente",
    computed: mapState({
        currentService: (state) => state.currentService,
        historiasClinicas: (state) => state.historiasClinicas,
    }),
    data: () => ({
        editTextArea: false,
        motivo_consulta: '',
        enf_actual: '',
        revision_sistemas: '',
        tratamiento_previo: '',
        antecedentes: '',
        antecedentes2: '',
    }),
    created() {
        const serviceData = this.historiasClinicas[this.currentService];
        this.motivo_consulta = serviceData.motivo_consulta ?? '';
        this.enf_actual = serviceData.enf_actual ?? '';
        this.revision_sistemas = serviceData.revision_sistemas ?? '';
        this.tratamiento_previo = serviceData.tratamiento_previo ?? '';
        this.antecedentes = serviceData.antecedentes ?? '';
        this.antecedentes2 = serviceData.antecedentes2 ?? '';

    },
    methods: {
        update(event, key) {
            this.$store.commit("setKeyToHistory", {
                consecutivo: this.currentService,
                key,
                value: event.target ? event.target.value : event,
            });
            console.log(this.$store.state.historiasClinicas[this.currentService].motivo_consulta);
            console.log(this.$store.state.historiasClinicas[this.currentService]);
        },
        updateTextArea() {
            sweetAlert
                .fire({
                    title: "Esta seguro que desea modificar los antecedentes guardados?",
                    showCancelButton: true,
                    confirmButtonText: `ACEPTAR`,
                    cancelButtonText: `CANCELAR`,
                    reverseButtons: true,
                })
                .then(async (result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        this.editTextArea = true;
                    }
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.container {
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;

    input {
        border: none;
        border-bottom: black solid 1px;
        width: 100%;
    }

    h4 {
        margin-top: 1rem;
    }

    .text-grey {
        color: grey;
    }

    .text-dark {
        color: black;
    }
}

.text-container {
    textarea {
        width: 100%;
        background: white;
        border-radius: 0;
        height: 6rem;
        padding-left: 2rem;
        padding-top: 1rem;
    }

    .button-relative {
        position: relative;
        top: -1.8rem;
        left: 90%;
    }
}

.last-container {
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
}
</style>
